import Cookie from 'js-cookie';
export const isLogin = () => {
    try {
        let loginCookie = Cookie.get('provider_cookie');
        loginCookie = JSON.parse(loginCookie)
        if (loginCookie?.xpr_user_id && loginCookie?.xpr_user_id !== '') {
            return true;
        }   
    } catch (error) {
        return false;
    }
}

export const getCookie = () => {
    try {
        let loginCookie = Cookie.get('provider_cookie');
        loginCookie = JSON.parse(loginCookie)
        if (loginCookie?.xpr_user_id && loginCookie?.xpr_user_id !== '') {
            return loginCookie;
        }
        return null;   
    } catch (error) {
        return null;
    }
}