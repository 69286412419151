
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getDomainList } from "../../services";
import { updateRequest } from "../../store/slices/provider";
import { isEmpty } from "../../utils";
export const Step1 = () => {
    const [practiceType, setPracticeType] = useState([]);
    const providerRequest = useSelector((state) => state.provider);
    const selectedPracticeType = providerRequest?.clinicPracticeType?.id;
    const navigate = useNavigate();
    useEffect(() => {
		getDomainList().then((success) => {
			if (success.status.error === false) {
				setPracticeType(success.domain);
			}
		}).catch((err) => {
			console.log("getDomainList: err", err);
		});
	}, []);
    return (
        <>
            <section class="practice-step-sec">

                <div class="progress m-0 custom-progress overflow-visible">
                    <div class="progress-bar overflow-visible" role="progressbar" style={{"width": "33%"}}> <span
                        class="fw-medium text-primary progper">33%</span></div>
                </div>

                <div class="container-fluid border-bottom py-5 mb-5">
                    <div class="row">
                        <div class="col-12">
                            <div class="top-menu-sec">
                                <ul class="p-0 m-0 d-md-flex justify-content-center">
                                    <li class="step-list active">

                                        <div class="img-box">
                                            <i class="bi bi-plus-circle-dotted"></i>
                                        </div>
                                        <div class="txt-box">
                                            <small>Step 1/3</small>
                                            <span class="d-block fw-medium">Practice type</span>
                                        </div>

                                    </li>
                                    <li class="step-list">

                                        <div class="img-box">
                                            <i class="bi bi-person-add"></i>
                                        </div>
                                        <div class="align-self-center txt-box">
                                            <small>Step 2/3</small>
                                            <span class="d-block fw-medium">General info</span>
                                        </div>

                                    </li>
                                    <li class="step-list">

                                        <div class="img-box">
                                            <i class="bi bi-geo-alt"></i>
                                        </div>
                                        <div class="align-self-center txt-box">
                                            <small>Step 3/3</small>
                                            <span class="d-block fw-medium">Location</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container steps-container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="row">
                                <div class="col-12">
                                    <div class="steps-title-section">
                                        <h2 class="mb-3">Setting up your practice</h2>
                                        <div class="fw-medium fs-5">Add your practice information.</div>
                                        <div>Please ensure that all of the following information is correct.We will use this
                                            profile for google listing and marketing purposes.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                            {
                                    practiceType.map((item) => {
                                        return (
                                            <React.Fragment key={item.id}>
                                                <Icons item={item}></Icons>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </div>
                            <div class="row">
                                <div class="col-12 my-4">
                                    <div class="bottom-btn-sec d-flex justify-content-between">
                                        <button class="btn btn-outline-primary me-1" onClick={() => {
                                            navigate('/step7');
                                        }}>Back</button>
                                        <button class="btn btn-primary ms-1" disabled={isEmpty(selectedPracticeType)}  onClick={() => {
                                            navigate('/clinic/step2');
                                        }}>Next</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Icons = (props) => {
	const { item } = props;
	const [image, setImage] = useState('');
    const providerRequest = useSelector((state) => state.provider);
    const dispatch = useDispatch();
    const selectedPracticeType = providerRequest?.clinicPracticeType?.id;
    const setSelectedPracticeType = (item) => {
        dispatch(updateRequest({
            ...providerRequest,
            clinicPracticeType: item
        }));
    }
	useEffect(() => {
		const loadImage = async () => {
			const _image = await import('../../images/' + item.icon)
			setImage(_image.default);
		}
		loadImage();
	}, []);
	return (
		<div className="col-6 col-md-3" key={item.id}>
			<div className="select-practice-btn">
				<input type="radio" className="btn-check" name="select-practice-radio"
					checked={item.id === selectedPracticeType}
					id={item.id + item.title} value={item.id} onClick={(e) => { setSelectedPracticeType(item) }} />
				<label className="btn border rounded w-100" htmlFor={item.id + item.title}>
					<img src={image} className="m-3" />
                    <small className="practice-type-title">{item.title}</small>
				</label>
			</div>
		</div>
	)
}