import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getServiceList } from "../../services";
import { updateRequest } from "../../store/slices/provider";

export const Step3 = () => {
    const [services, setServices] = useState([]);
    const providerRequest = useSelector((state) => state.provider);
    const selectedSpeciality = providerRequest?.speciality;
    const selectedServices = services?.filter((item) => {
        if (providerRequest?.services?.includes(item.id)) {
            return true;
        }
        return false;
    });
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        getServiceList(selectedSpeciality).then((success) => {
            if (success.status.error === false) {
                setServices(success?.services);
            }
        }).catch((err) => {
            console.log("getServiceList: err", err);
        });
    }, []);
    return (
        <section class="practice-step-sec">

            <div class="progress custom-progress">
                <div class="progress-bar" role="progressbar" style={{ "width": "42%" }}> <span
                    class="fw-medium text-primary progper">42%</span></div>
            </div>

            <div class="step-navigation">
                <div class="container-fluid">
                    <div class="top-menu-sec">
                        <ul class="p-0 m-0 d-md-flex justify-content-center">
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-plus-circle-dotted"></i>
                                </div>
                                <div class="txt-box">
                                    <small>Step 1/8</small>
                                    <span class="d-block fw-medium">Practice type</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-person-add"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 2/8</small>
                                    <span class="d-block fw-medium">Personal Info</span>
                                </div>

                            </li>
                            <li class="step-list active">

                                <div class="img-box">
                                    <i class="bi bi-asterisk"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 3/8</small>
                                    <span class="d-block fw-medium">Services</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-search"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 4/8</small>
                                    <span class="d-block fw-medium">Discoverability Score</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-award"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 5/8</small>
                                    <span class="d-block fw-medium">Education & Certifications</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-images"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 6/8</small>
                                    <span class="d-block fw-medium">Upload images</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 7/8</small>
                                    <span class="d-block fw-medium">Availablity</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock-history"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 8/8</small>
                                    <span class="d-block fw-medium">Open to work</span>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Services</h2>
                                    <div class="steps-subtitle">Which services are offered by you?</div>
                                    <div class="steps-desc">Please set the services offered</div>
                                </div>

                                <div class="step-content">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="features-check-list custom-check-list d-flex flex-wrap">
                                                {services.map((item) => {
                                                    return (
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={providerRequest?.services?.includes(item.id)} value={item.id} id={item.name}
                                                                onChange={(e) => {
                                                                    if (!providerRequest?.services?.includes(item.id)) {
                                                                        dispatch(updateRequest({
                                                                            ...providerRequest,
                                                                            services: [...selectedServices, item].map((item) => item.id).toString()
                                                                        }));
                                                                    } else {
                                                                        dispatch(updateRequest({
                                                                            ...providerRequest,
                                                                            services: selectedServices?.filter((cItem) => cItem.id !== item.id).map((item) => item.id).toString()
                                                                        }));
                                                                    }
                                                                }}
                                                            />
                                                            <label className="form-check-label rounded-2 border w-100" htmlFor={item.name}>
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" onClick={() => {
                                        navigate('/step2');
                                }}>Back</button>
                                <button class="btn btn-primary ms-1" onClick={() => {
                                        navigate('/step4');
                                }}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}