import { useNavigate } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useState } from "react";
import { createNewClinic } from "../../services";
import { useSelector } from "react-redux";
import { dataURLtoFile } from "../../utils";
const _Step3 = (props) => {
    const navigate = useNavigate();
    const [place, setPlace] = useState('');
    const [formattedAddress, setFormattedAddress] = useState();
    const providerRequest = useSelector((state) => state.provider);
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8",
        defaultValue: place,
        onPlaceSelected: (place) => {
            if (place) {
                let componentForm = {
                    subpremise: '',
                    street_number: '',
                    route: '',
                    sublocality_level_1: '',
                    locality: '',
                    administrative_area_level_1: '',
                    country: '',
                    postal_code: '',
                    latitude: '',
                    longitude: '',
                    address_level_1_short: '',
                    neighborhood: '',
                    formatted_address: ''
                };
                for (let start = 0; start < place.address_components.length; start++) {
                    const addressType = place.address_components[start].types[0];
                    if (addressType === "street_number") {
                        componentForm.street_number = place.address_components[start].long_name;
                    } else if (addressType === "subpremise") {
                        componentForm.subpremise = place.address_components[start].short_name;
                    } else if (addressType === "route") {
                        componentForm.route = place.address_components[start].long_name;
                    } else if (addressType === "sublocality_level_1") {
                        componentForm.sublocality_level_1 = place.address_components[start].long_name;
                    } else if (addressType === "locality") {
                        componentForm.locality = place.address_components[start].long_name;
                    } else if (addressType === "administrative_area_level_1") {
                        componentForm.administrative_area_level_1 = place.address_components[start].long_name;
                        componentForm.address_level_1_short = place.address_components[start].short_name;
                    } else if (addressType === "country") {
                        componentForm.country = place.address_components[start].long_name;
                    } else if (addressType === "postal_code") {
                        componentForm.postal_code = place.address_components[start].short_name;
                    } else if (addressType === 'neighborhood') {
                        componentForm.neighborhood = place.address_components[start].long_name;
                    }
                    componentForm.latitude = place.geometry.location.lat();
                    componentForm.longitude = place.geometry.location.lng();
                    componentForm.formatted_address = place.formatted_address.trim();
                }
                setPlace(place.formatted_address.trim());
                setFormattedAddress(componentForm);
            }
        },
        options: {
            types: ['address']
        }
    });
    const _createNewClinic = () => {
        const obj = {};
        providerRequest?.clinicUploadedPhotos?.forEach((item, index) => {
            obj[`uphoto[${index}]`] = dataURLtoFile(item?.url, item?.file?.name);
        });
        obj.name = providerRequest?.clinicName;
        obj.email = providerRequest?.clinicEmail;
        obj.phone = providerRequest?.clinicPhoneNumber;
        obj.phone = providerRequest?.clinicPhoneNumber;
        obj.domain = providerRequest?.clinicPracticeType?.id;
        obj.website = providerRequest?.clinicWebsite;
        obj.speciality = providerRequest?.clinicSpeciality;
        obj.logo = (providerRequest?.clinicPreviewFile && providerRequest?.clinicLogo?.name) ?  dataURLtoFile(providerRequest?.clinicPreviewFile, providerRequest?.clinicLogo?.name) : undefined;
        obj.address = JSON.stringify(formattedAddress);
        obj.features = providerRequest?.selectedFeatures;
        createNewClinic(obj).then((success) => {
            if (success.status.error === false) {
                navigate('/step7')
            }
        }).catch((error) => {
            if (error?.error === true)  {
                alert(error?.msg);
            } else {
                alert('Failed to create new clinic');
            }
        });
    }
    return (
        <section class="practice-step-sec">
            <div class="progress m-0 custom-progress overflow-visible">
                <div class="progress-bar overflow-visible" role="progressbar" style={{ "width": "66%;" }}> <span
                    class="fw-medium text-primary progper">66%</span></div>
            </div>

            <div class="container-fluid border-bottom py-5 mb-5">
                <div class="row">
                    <div class="col-12">
                        <div class="top-menu-sec">
                            <ul class="p-0 m-0 d-md-flex justify-content-center">
                                <li class="step-list completed">

                                    <div class="img-box">
                                        <i class="bi bi-plus-circle-dotted"></i>
                                    </div>
                                    <div class="txt-box">
                                        <small>Step 1/3</small>
                                        <span class="d-block fw-medium">Practice type</span>
                                    </div>

                                </li>
                                <li class="step-list completed">

                                    <div class="img-box">
                                        <i class="bi bi-person-add"></i>
                                    </div>
                                    <div class="align-self-center txt-box">
                                        <small>Step 2/3</small>
                                        <span class="d-block fw-medium">General info</span>
                                    </div>

                                </li>
                                <li class="step-list active">

                                    <div class="img-box">
                                        <i class="bi bi-geo-alt"></i>
                                    </div>
                                    <div class="align-self-center txt-box">
                                        <small>Step 3/3</small>
                                        <span class="d-block fw-medium">Location</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Location</h2>
                                    <div class="steps-subtitle">What is your practice address?</div>
                                    <div class="steps-desc">We want to ensure that we’re sending patients to the right
                                        place.</div>
                                </div>

                                <div class="step-content">
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <p class="text-danger">If you don’t have your practice listed on google maps
                                                please add or connect with our team.</p>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-sm-6 mb-4">
                                                    <div class="img-box location-img-box">
                                                        <Map google={props.google} zoom={14} containerStyle={{
                                                            width: '300px',
                                                            height: '300px'
                                                        }}>
                                                            <Marker />
                                                        </Map>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 mb-4">
                                                    <label for="Specialties" class="form-label">Business location</label>
                                                    <input type="text" class="form-control" id="practicetName"
                                                        name="Specialties" placeholder="" ref={ref} />
                                                    <small class="text-muted">Your business must be listed on google maps.</small>
                                                    <div class="invalid-feedback">Required</div>
                                                    <div class="invalid-feedback">Allow only A-Za-z0-9_@./#&+-</div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" onClick={() => {
                                    navigate('/clinic/step2');
                                }}>Back</button>
                                <button disabled={place?.trim() === ''} class="btn btn-primary ms-1" onClick={() => {
                                    _createNewClinic();
                                }}>Finish</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export const Step3 = GoogleApiWrapper({
    apiKey: "AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8",
})(_Step3);