import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import RichTextEditor from 'react-rte';
import { useNavigate } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import { getQuestionsList } from "../../services";
import { updateRequest } from "../../store/slices/provider";

export const Step4 = () => {
    const providerRequest = useSelector((state) => state.provider);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const components = {
        DropdownIndicator: null,
    };
    const [inputValue, setInputValue] = useState('');

    const handleChange = (value, actionMeta) => {
        dispatch(updateRequest({
            ...providerRequest,
            metaKeywords: value
        }));
    };
    const createOption = (label) => ({
        label,
        value: label,
    });
    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
    };
    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                dispatch(updateRequest({
                    ...providerRequest,
                    metaKeywords: [...providerRequest?.metaKeywords, createOption(inputValue)]
                }));
                event.preventDefault();
            default:
                return;
        }
    };

    useEffect(() => {
        getQuestionsList().then((success) => {
            if (success.status && success.status.error === false) {
                dispatch(updateRequest({
                    ...providerRequest,
                    questionResponse: success.questions.map((item) => ({ ...item, response: providerRequest?.questionResponse?.find((_ques) => _ques.id === item.id)?.response ?? "", error: false }))
                }));
            }
        }).catch((err) => {
        })
    }, []);
    const validate = () => {
        if (providerRequest?.description?.trim() === '') {
            return false;
        }
        if (providerRequest?.questionResponse?.some((item) => item?.response?.trim() === '')) {
            return false;
        }
        return true;
    }
    return (
        <section class="practice-step-sec">

            <div class="progress custom-progress">
                <div class="progress-bar" role="progressbar" style={{ "width": "56%" }}> <span
                    class="fw-medium text-primary progper">56%</span></div>
            </div>

            <div class="step-navigation">
                <div class="container-fluid">
                    <div class="top-menu-sec">
                        <ul class="p-0 m-0 d-md-flex justify-content-center">
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-plus-circle-dotted"></i>
                                </div>
                                <div class="txt-box">
                                    <small>Step 1/8</small>
                                    <span class="d-block fw-medium">Practice type</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-person-add"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 2/8</small>
                                    <span class="d-block fw-medium">Personal Info</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-asterisk"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 3/8</small>
                                    <span class="d-block fw-medium">Services</span>
                                </div>

                            </li>
                            <li class="step-list active">

                                <div class="img-box">
                                    <i class="bi bi-search"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 4/8</small>
                                    <span class="d-block fw-medium">Discoverability Score</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-award"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 5/8</small>
                                    <span class="d-block fw-medium">Education & Certifications</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-images"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 6/8</small>
                                    <span class="d-block fw-medium">Upload images</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 7/8</small>
                                    <span class="d-block fw-medium">Availablity</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock-history"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 8/8</small>
                                    <span class="d-block fw-medium">Open to work</span>
                                </div>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Discoverablity score</h2>
                                    <div class="steps-subtitle">Profile description</div>
                                    <div class="steps-desc">Please ensure that all of the following information is correct. We will use this
                                        profile for your listing and for marketing purposes.</div>
                                </div>

                                <div class="step-content">
                                    <div class="info-question">
                                        <div class="col-12 mb-4">
                                            <label for="" class="form-label">Profile Bio <span class="text-danger">*</span></label>
                                            <textarea
                                                className="form-control"
                                                value={providerRequest?.description}
                                                rows={5}
                                                cols={5}
                                                onChange={(e) => {
                                                    dispatch(updateRequest({
                                                        ...providerRequest,
                                                        description: e.target.value
                                                    }))
                                                }}
                                            ></textarea>
                                            <small class="text-muted">This bio will help new patients find your clinic on google.</small>
                                            <div class="invalid-feedback">
                                                Required
                                            </div>
                                        </div>

                                        {
                                            providerRequest?.questionResponse?.map((item, index) => {
                                                return (
                                                    <div className="col-12 mb-4" key={item.id + index}>
                                                        <label htmlFor={"Q" + (index + 1)} className="form-label">{item.title} <span className="text-danger">*</span></label>
                                                        <textarea className="form-control" id={"Q" + (index + 1)} rows={3} value={item.response} onChange={(e) => {
                                                            dispatch(updateRequest({
                                                                ...providerRequest,
                                                                questionResponse: providerRequest?.questionResponse.map((qItem) => {
                                                                    if (item.id === qItem.id) {
                                                                        return {
                                                                            ...qItem,
                                                                            response: e.target.value
                                                                        }
                                                                    }
                                                                    return qItem
                                                                })
                                                            }));
                                                        }}></textarea>
                                                        {
                                                            item.error &&
                                                            <div className="is-invalid">Required</div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }


                                    </div>

                                    <div class="meta-info mt-5">

                                        <div class="steps-title-section mb-4">
                                            <div class="steps-subtitle">Meta Data</div>
                                            <div class="steps-desc">Please ensure that all of the following information is correct. We will use
                                                this profile for your listing and for marketing purposes.</div>
                                        </div>

                                        <div class="meta-data-sec">
                                            <div class="tdk-box mb-4">
                                                <label htmlFor="seoTitle" class="form-label">SEO title</label>
                                                <input class="form-control" id="seoTitle" type="text" value={providerRequest?.metaTitle} onChange={(e) => {
                                                    dispatch(updateRequest({
                                                        ...providerRequest,
                                                        metaTitle: e.target.value
                                                    }));
                                                }} />
                                                <small class="text-muted">Please set your profile description to let patients know about your
                                                    practice.</small>
                                            </div>
                                            <div class="tdk-box mb-4">
                                                <label htmlFor="metaDescription" class="form-label">Meta description</label>
                                                <textarea class="form-control" id="metaDescription" rows="4" value={providerRequest?.metaDesc} onChange={(e) => {
                                                    dispatch(updateRequest({
                                                        ...providerRequest,
                                                        metaDesc: e.target.value
                                                    }));
                                                }}></textarea>
                                                <small class="text-muted">Please set your profile description to let patients know about your
                                                    practice.</small>
                                            </div>
                                            <div class="tdk-box mb-4">
                                                <label class="form-label">Target keyword</label>
                                                <CreatableSelect
                                                    components={components}
                                                    inputValue={inputValue}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={handleChange}
                                                    onInputChange={handleInputChange}
                                                    onKeyDown={handleKeyDown}
                                                    placeholder="Keywords"
                                                    value={providerRequest?.metaKeywords}
                                                />
                                                <small class="text-muted">Please set your profile description to let patients know about your
                                                    practice.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" onClick={() => {
                                    navigate('/step3');
                                }}>Back</button>
                                <div>
                                    <button disabled={!validate()} class="btn btn-primary ms-1" onClick={() => {
                                        if (validate()) {
                                            navigate('/step5');
                                        }
                                    }}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}