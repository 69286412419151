import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../images/logo.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import { useForm } from 'react-hook-form';
import { login } from '../../services';


const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        setIsLoading(true);
        login({ login: data.email, password: data.password }).then((success) => {
            if (success && success.status && success.status.error === false) {
                Cookie.remove('provider_cookie');
                const key1 = 'xprUser.provider-' + success.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'jwtToken';
                var json = {};
                json[key1] = true;
                json[key2] = success.uniqueId;
                json[key3] = success.authParam;
                json[key4] = success.jwtToken;
                Cookie.set('provider_cookie', JSON.stringify(json), { expires: 30 });
                setIsLoading(false);
                navigate('/');
            }
        }).catch((err) => {
            setIsLoading(false);
            if (err && err.error === true) {
                setError('email', { type: 'invalidemailpass', message: err.msg });
            } else {
                setError('email', { type: 'someerr', message: 'Something went wrong.' });
            }
        });
    };
    return (
        <>
            <div className="app login-container d-lg-flex min-vh-100 d-flex">
                <div className="form-signin">
                    <Helmet><title>Signin - xcarepro</title></Helmet>
                    <div className="card shadow-sm">

                        <div className="card-header bg-light px-4 py-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <img src={logo} alt="Logo" width="100px" />
                            </div>
                        </div>

                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="email">Email address: </label>
                                    <input type="text" className="form-control" placeholder="Email address"
                                        id="email"
                                        {...register("email", {
                                            required: true,
                                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })}
                                    />
                                    {
                                        (errors && errors.email && errors.email.type === "required") &&
                                        <div className="text-danger">Email is required.</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "pattern") &&
                                        <div className="text-danger">Email format is invalid.</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "invalidemailpass") &&
                                        <div className="text-danger">{errors.email.message}</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "someerr") &&
                                        <div className="text-danger">{errors.email.message}</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "firebaseloginerr") &&
                                        <div className="text-danger">{errors.email.message}</div>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="password">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="Password"
                                        {...register("password", { required: true })} />
                                    {
                                        (errors && errors.password && errors.password.type === "required") &&
                                        <div className="text-danger">Password is required.</div>
                                    }
                                </div>
                                <div className="">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                            <NavLink to="/" className="text-decoration-none link-danger">New user?</NavLink>
                                            <br/>    
                                            <NavLink to="/forgotpassword" className="text-decoration-none link-danger">Forgot Password</NavLink>
                                        </span>
                                        <button id="signin-button" className="btn btn-primary" disabled={isLoading}>
                                            {
                                                isLoading &&
                                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                            }
                                            Sign in</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;