import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import configs from "../../config";
import AppStoreBadge from "../../images/app-store-badge.svg";
import PlayStoreBadge from "../../images/play-store-badge.svg";
import { resetProviderState } from "../../store/slices/provider";
import Cookie from 'js-cookie';
export const Thankyou = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const providerRequest = useSelector((state) => state.provider);
    useEffect(() => {
        if (!location?.state?.providerId) {
            navigate('/');
        }
    }, []);
    const handleLogout = () => {
        dispatch(resetProviderState());
        Cookie.remove('provider_cookie');
    }
    return (
        <section class="full-height">
        <div class="container h-100">
            <div class="row justify-content-center align-items-center h-100">
                <div class="col-12 col-md-6 text-center">
                    <div class="title mb-3">Thank you for completing your profile.</div>
                    <p class="txt-content mx-auto">Please review how your profile will be viewed on Xcare.</p>
                    <div className="d-flex flex-column">
                        {
                            location?.state?.providerId?.map((item, index) => {
                                const url = providerRequest?.fullname?.trim().toLowerCase().replace(/[,._+]/g, '').replace(/\s+/g, "-") + '/' + item + '/d';
                                return (
                                    <a href={configs.SOURCE_URL + url} target="_blank">Profile {index + 1}</a>
                                )
                            })
                        }
                    </div>
                    <p class="txt-content mx-auto mt-2">You will receive an activation email shortly. Upon completing activation, you can edit your profile by signing in.</p>
                    <p class="txt-content mx-auto">Please download the mobile provider app to sign in.</p>
                    <div class="d-flex download-links justify-content-center">
                        <a href="https://play.google.com/store/apps/details?id=com.xcarepro.android" target={"_blank"} class="d-block m-3">
                            <img src={PlayStoreBadge} alt=""/>
                        </a>
                        <a href="https://apps.apple.com/us/app/x-care/id1517457992?ls=1" target={"_blank"} class="d-block m-3">
                            <img src={AppStoreBadge} alt=""/>
                        </a>
                    </div>
                    {
                        providerRequest?.editMode &&
                        <NavLink to="/signin" onClick={handleLogout}>Logout</NavLink>
                    }
                </div>
            </div>

        </div>
    </section>
    )
}