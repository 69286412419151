export const Step8 = () => {
    return (
        <section class="practice-step-sec">

            <div class="progress custom-progress">
                <div class="progress-bar" role="progressbar" style={{ "width": "99%" }}> <span
                    class="fw-medium text-primary progper">99%</span></div>
            </div>

            <div class="step-navigation">
                <div class="container-fluid">
                    <div class="top-menu-sec">
                        <ul class="p-0 m-0 d-md-flex justify-content-center">
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-plus-circle-dotted"></i>
                                </div>
                                <div class="txt-box">
                                    <small>Step 1/8</small>
                                    <span class="d-block fw-medium">Practice type</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-person-add"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 2/8</small>
                                    <span class="d-block fw-medium">Personal Info</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-asterisk"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 3/8</small>
                                    <span class="d-block fw-medium">Services</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-search"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 4/8</small>
                                    <span class="d-block fw-medium">Discoverability Score</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-award"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 5/8</small>
                                    <span class="d-block fw-medium">Education & Certifications</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-images"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 6/8</small>
                                    <span class="d-block fw-medium">Upload images</span>
                                </div>

                            </li>

                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 7/8</small>
                                    <span class="d-block fw-medium">Availablity</span>
                                </div>

                            </li>

                            <li class="step-list active">

                                <div class="img-box">
                                    <i class="bi bi-clock-history"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 8/8</small>
                                    <span class="d-block fw-medium">Open to work</span>
                                </div>

                            </li>



                        </ul>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Open to work</h2>
                                    <div class="steps-subtitle">Open to work on any specific days</div>
                                    <div class="steps-desc">If you don't see your practice listed in the list below, please click on “Don't see my practice/clinic” link below.</div>
                                </div>

                                <div class="step-content">

                                    <div class="col-12 open-to-work-days">
                                        <ul class="d-flex days-checklist">
                                            <li class="form-check"><input class="form-check-input" type="checkbox" id="MON" value="MON" /><label
                                                class="form-check-label rounded-2 border w-100" for="MON">MON</label></li>
                                            <li class="form-check"><input class="form-check-input" type="checkbox" id="TUE" value="TUE" /><label
                                                class="form-check-label rounded-2 border w-100" for="TUE">TUE</label></li>
                                            <li class="form-check"><input class="form-check-input" type="checkbox" id="WED" value="WED" /><label
                                                class="form-check-label rounded-2 border w-100" for="WED">WED</label></li>
                                            <li class="form-check"><input class="form-check-input" type="checkbox" id="THU" value="THU" /><label
                                                class="form-check-label rounded-2 border w-100" for="THU">THU</label></li>
                                            <li class="form-check"><input class="form-check-input" type="checkbox" id="FRI" value="FRI" /><label
                                                class="form-check-label rounded-2 border w-100" for="FRI">FRI</label></li>
                                            <li class="form-check"><input class="form-check-input" type="checkbox" id="SAT" value="SAT" /><label
                                                class="form-check-label rounded-2 border w-100" for="SAT">SAT</label></li>
                                            <li class="form-check"><input class="form-check-input" type="checkbox" id="SUN" value="SUN" /><label
                                                class="form-check-label rounded-2 border w-100" for="SUN">SUN</label></li>
                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" onclick="window.location.href='step-7.html';">Back</button>
                                <button class="btn btn-primary ms-1" onclick="window.location.href='finish.html';">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    )
}