import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { getFeatures, getSpeciality } from "../../services";
import { updateRequest } from "../../store/slices/provider";
import configs from "../../config";
import { emailValidator, urlValidator } from "../../utils/validators";

export const Step2 = () => {
    const providerRequest = useSelector((state) => state.provider);
    const dispatch = useDispatch();
    const uploadRef = useRef();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            previewFile: providerRequest?.clinicPreviewFile ?? '',
            clinicLogo: providerRequest?.clinicLogo,
            name: providerRequest?.clinicName,
            email: providerRequest?.clinicEmail,
            phonenumber: providerRequest?.clinicPhoneNumber,
            website: providerRequest?.clinicWebsite,
            speciality: providerRequest?.clinicSpeciality,
        },
        validate: async (values) => {
            const errors = {};
            if (!values?.previewFile || values.previewFile === '') {
                errors.clinicLogo = 'Profile picture is required';
            }
            if (!values.name || values.name?.trim() === '') {
                errors.name = 'Name is required';
            }
            if (values.email === '') {
                errors.email = 'Email is required';
            }
            if (!emailValidator(values.email)) {
                errors.email = 'Please enter valid email';
            }
            if (values.phonenumber === '') {
                errors.phonenumber = 'Mobile number is required';
            }
            if (values.phonenumber?.trim().length !== 10) {
                errors.phonenumber = 'Mobile number should be of 10 digit';
            }
            if (isNaN(values.phonenumber)) {
                errors.phonenumber = 'Mobile number should be in digit';
            }
            if (values.website === '') {
                errors.website = 'Website is required';
            }
            if (!urlValidator(values.website)) {
                errors.website = 'Please enter valid website, it should start with https://';
            }
            if (!values.speciality || values.speciality === '') {
                errors.speciality = 'Speciality is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            dispatch(updateRequest({
                ...providerRequest,
                clinicName: values.name,
                clinicEmail: values.email,
                clinicPhoneNumber: values.phonenumber,
                clinicWebsite: values.website,
                clinicSpeciality: values.speciality,
            }))
            navigate('/clinic/step3');
        },
    });
    const [specialties, setSpecialities] = useState([]);
    const [practiceGeneralFeature, setPracticeGeneralFeature] = useState([]);
    const selectedPracticeType = providerRequest?.clinicPracticeType?.id;
    const selectedFeatures = practiceGeneralFeature?.filter((item) => {
        if (providerRequest?.selectedFeatures?.includes(item.id)) {
            return true;
        }
        return false;
    });
    const selectedSpecilaties = specialties?.filter((item) => {
        if (providerRequest?.clinicSpeciality?.includes(item.value)) {
            return true;
        }
        return false;
    });
    useEffect(() => {
        getSpeciality(selectedPracticeType).then((success) => {
            if (success.status.error === false) {
                setSpecialities(success.specialities.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }));
            }
        }).catch((error) => {
            console.log("getSpeciality: error", error);
        });
    }, []);

    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/png" ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg')) {
            formik.setFieldValue('logo', event.target.files[0]);
            // dispatch(updateRequest({
            //     ...providerRequest,
            //     logo: event.target.files[0]
            // }));
            var reader = new FileReader();
            var url = reader.readAsDataURL(event.target.files[0]);
            reader.onloadend = function (e) {
                //Initiate the JavaScript Image object.
                var image = new Image();
                //Set the Base64 string return from FileReader as source.
                image.src = e.target.result;
                //Validate the File Height and Width.
                image.onload = function () {
                    var height = image.height;
                    var width = image.width;
                    if (height < 256 || width < 256) {
                        formik.setFieldError('clinicLogo', 'Please upload valid file');
                        return false;
                    }
                    formik.setFieldValue('previewFile', reader.result);
                    dispatch(updateRequest({
                        ...providerRequest,
                        clinicPreviewFile: reader.result,
                        clinicLogo: {
                            type: file.type,
                            name: file.name
                        }
                    }));
                    return true;
                };
            };
            return;
        } else {
            formik.setFieldError('clinicLogo', 'Please upload file');
        }
    }


    let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: 'image/jpeg, image/png, image/jpg',
		multiple: false,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles[0] && acceptedFiles[0]?.type?.includes("image")) {
                var reader = new FileReader();
                var url = reader.readAsDataURL(acceptedFiles[0]);
                reader.onloadend = function (e) {
                    //Initiate the JavaScript Image object.
                    var image = new Image();
                    //Set the Base64 string return from FileReader as source.
                    image.src = e.target.result;
                    //Validate the File Height and Width.
                    image.onload = function () {
                        dispatch(updateRequest({
                            ...providerRequest,
                            clinicUploadedPhotos: [...providerRequest.clinicUploadedPhotos, { 
                                url: reader.result,
                                file: {
                                    type: acceptedFiles[0].type,
                                    name: acceptedFiles[0].name
                                }
                            }]
                        }));
                        return true;
                    };
                };
			}
		}
	});
    const onDeleteImage = (index) => {
        const temp = providerRequest?.clinicUploadedPhotos?.filter((item, _index) => index !== _index);
        dispatch(updateRequest({
            ...providerRequest,
            clinicUploadedPhotos: temp
        }));
	}

    useEffect(() => {
        _getFeatures();
    }, [formik.values.speciality])

    const _getFeatures = () => {
        if (formik.values.speciality && formik.values.speciality !== '') {
            getFeatures(formik.values.speciality).then((success) => {
                if (success.status && success.status.error === false) {
                    setPracticeGeneralFeature(success.features.map((item) => ({ ...item })));
                }
            }).catch((err) => {
            });
        }
    }
    console.log(formik.errors);
    return (
        <section class="practice-step-sec">
            <div class="progress m-0 custom-progress overflow-visible">
                <div class="progress-bar overflow-visible" role="progressbar" style={{"width": "66%"}}> <span
                    class="fw-medium text-primary progper">66%</span></div>
            </div>

            <div class="container-fluid border-bottom py-5 mb-5">
                <div class="row">
                    <div class="col-12">
                        <div class="top-menu-sec">
                            <ul class="p-0 m-0 d-md-flex justify-content-center">
                                <li class="step-list completed">

                                    <div class="img-box">
                                        <i class="bi bi-plus-circle-dotted"></i>
                                    </div>
                                    <div class="txt-box">
                                        <small>Step 1/3</small>
                                        <span class="d-block fw-medium">Practice type</span>
                                    </div>

                                </li>
                                <li class="step-list active">

                                    <div class="img-box">
                                        <i class="bi bi-person-add"></i>
                                    </div>
                                    <div class="align-self-center txt-box">
                                        <small>Step 2/3</small>
                                        <span class="d-block fw-medium">General info</span>
                                    </div>

                                </li>
                                <li class="step-list">

                                    <div class="img-box">
                                        <i class="bi bi-geo-alt"></i>
                                    </div>
                                    <div class="align-self-center txt-box">
                                        <small>Step 3/3</small>
                                        <span class="d-block fw-medium">Location</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">General info</h2>
                                    <div class="steps-subtitle">Add your practice information.</div>
                                    <div class="steps-desc">Please ensure that all of the following information is
                                        correct.We will use this profile for google listing and marketing purposes.</div>
                                </div>

                                <div class="step-content">
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <div class="profile-logo-sec d-flex">
                                                    <div class="img-box rounded-circle border position-relative">
                                                        {
                                                            formik.values.previewFile && formik.values.previewFile !== '' &&
                                                            <button class="btn text-white position-absolute top-0 end-0 p-0 rounded-circle" onClick={() => {
                                                                formik.setFieldValue('clinicLogo', '');
                                                                formik.setFieldValue('previewFile', '');
                                                                uploadRef.current.value = '';
                                                            }}>
                                                                <i class="bi bi-x"></i>
                                                            </button>
                                                        }
                                                        <img className="rounded-circle" style={{ height: 120, width: 120 }} src={formik.values.previewFile}></img>
                                                    </div>
                                                    <div class="txt-box align-self-center ms-2 ms-md-4 m">
                                                        <input ref={uploadRef} type="file" style={{ "display": "none" }} onChange={onFileChange}></input>
                                                        <button class="btn border d-block fw-normal" onClick={(e) => {
                                                            uploadRef.current.click();
                                                        }}>Upload profile picture</button>
                                                        <span class="d-block mt-2 text-muted">At least 256 x 256px PNG or JPG file.</span>
                                                        {
                                                            formik.errors.clinicLogo &&
                                                            <div class="text-danger">{formik.errors.clinicLogo}</div>
                                                        }
                                                        

                                                    </div>
                                                </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-sm-6 mb-4">
                                                        <label for="Specialties" class="form-label">Specialties</label>
                                                        <Select className="select2 myslt" options={specialties} isMulti
                                                            value={selectedSpecilaties}
                                                            onChange={(e) => {
                                                                formik.setFieldValue('speciality', e.map((item) => item.value).toString());
                                                                dispatch(updateRequest({
                                                                    ...providerRequest,
                                                                    clinicSpeciality: e.map((item) => item.value).toString()
                                                                }));
                                                            }}
                                                        ></Select>
                                                        {/* <input type="text" class="form-control" id="practicetName" name="Specialties" placeholder="" /> */}
                                                        <small class="text-muted">Ex. Dentist, Orthodontist, Hygienist, etc...</small>

                                                        {
                                                            formik.errors.speciality &&
                                                            <div class="text-danger">{formik.errors.speciality}</div>
                                                        }

                                                    </div>
                                                <div class="col-sm-6 mb-4">
                                                    <label for="Specialties" class="form-label">Practice name</label>
                                                    <input type="text" class="form-control" id="practicetName"
                                                        name="name" value={formik.values.name} onChange={formik.handleChange} placeholder="" />
                                                    {
                                                        formik.errors.name &&
                                                        <div class="text-danger">{formik.errors.name}</div>
                                                    }

                                                </div>
                                                <div class="col-sm-6 mb-4">
                                                    <label for="phoneNumber" class="form-label">Mobile number</label>
                                                    <input type="text" class="form-control" name="phonenumber" value={formik.values.phonenumber} onChange={formik.handleChange}/>

                                                    {
                                                        formik.errors.phonenumber &&
                                                        <div class="text-danger">{formik.errors.phonenumber}</div>
                                                    }

                                                </div>
                                                <div class="col-sm-6 mb-4">
                                                    <label for="emailAddress" class="form-label">Practice email
                                                        address</label>
                                                    <input type="email" class="form-control" id="emailAddress" name="email"
                                                        placeholder="xyz@provider.com" value={formik.values.email} onChange={formik.handleChange}/>

                                                    {
                                                        formik.errors.email &&
                                                        <div class="text-danger">{formik.errors.email}</div>
                                                    }

                                                </div>
                                                <div class="col-sm-6 mb-4">
                                                    <label for="practicetName" class="form-label">Website</label>
                                                    <input type="text" class="form-control" id="practicetName"
                                                        name="website" placeholder="" value={formik.values.website} onChange={formik.handleChange}/>

                                                    {
                                                        formik.errors.website &&
                                                        <div class="text-danger">{formik.errors.website}</div>
                                                    }

                                                </div>
                                                <div class="col-12">
                                                    <div class="steps-title-section my-4">
                                                        <div class="steps-subtitle">Preferences & highlights</div>
                                                        <div class="steps-desc d-none">Uploading your pictures helps boost your
                                                            profile on
                                                            Xcarepro. We recommend uploading at least 3-5 photos.</div>
                                                    </div>

                                                    <div class="step-content">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div
                                                                    class="features-check-list custom-check-list d-flex flex-wrap">
                                                                    {
                                                                        practiceGeneralFeature.map((item, index) => {
                                                                            return (
                                                                                <div className="form-check" key={item.id + item.name}>
                                                                                    <input className="form-check-input" type="checkbox" value={item.name} id={item.name + item.id} checked={item.isChecked} onChange={(e) => {
                                                                                        if (!providerRequest?.selectedFeatures?.includes(item.id)) {
                                                                                            dispatch(updateRequest({
                                                                                                ...providerRequest,
                                                                                                selectedFeatures: [...selectedFeatures, item].map((item) => item.id).toString()
                                                                                            }));
                                                                                        } else {
                                                                                            dispatch(updateRequest({
                                                                                                ...providerRequest,
                                                                                                selectedFeatures: selectedFeatures?.filter((cItem) => cItem.id !== item.id).map((item) => item.id).toString()
                                                                                            }));
                                                                                        }
                                                                                    }} />
                                                                                    <label className="form-check-label rounded-2 border w-100" htmlFor={item.name + item.id}>
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="steps-title-section my-4">
                                                        <div class="steps-subtitle">Practice Photos</div>
                                                        <div class="steps-desc">Uploading your pictures helps boost your
                                                            profile on Xcarepro. We recommend uploading at least 3-5
                                                            photos.</div>
                                                    </div>

                                                    <div class="step-content">
                                                        <div class="row mb-4">
                                                            <div class="col-12">
                                                                <div class="img-gallery-practice d-flex">
                                                                {
                                                                    providerRequest?.clinicUploadedPhotos?.map((item, index) => {
                                                                        return (
                                                                            <div className="img-box d-inline-block" key={index + ""}>
                                                                                <button className="btn p-0 position-absolute delete-img-btn" onClick={(e) => {
                                                                                    onDeleteImage(index);
                                                                                }}>
                                                                                    <i class="bi bi-trash"></i>
                                                                                </button>
                                                                                <img src={item?.url} height={115} width={115} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="border rounded upload-sec p-4"><label
                                                                    class="form-label">Upload</label>
                                                                    <section
                                                                        class="container upload-input-box py-5 text-center mb-4">
                                                                         <div {...getRootProps({ className: 'dropzone' })}>
                                                                            <input {...getInputProps()} />
                                                                            <em>(Only *.jpeg, *.jpg and *.png images will be accepted)</em>
                                                                            <div><button className="btn btn-link ps-0 py-0">Browse</button></div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1"
                                    onClick={() => {
                                        navigate('/clinic/step1');
                                    }}>Back</button>
                                <button class="btn btn-primary ms-1" onClick={(e) => {
                                    formik.handleSubmit(e)
                                }}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}