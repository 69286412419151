import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import { checkActivationKey, getDomainList, getProviderData, sendActivationKey } from "../../services";
import { updateRequest } from "../../store/slices/provider";
import { isEmpty } from "../../utils";
import { emailValidator } from "../../utils/validators";
import { isLogin } from "../../utils/login";
import configs from "../../config";
import moment from "moment/moment";

export const Step1 = () => {
    const [practiceType, setPracticeType] = useState([]);
    const providerRequest = useSelector((state) => state.provider);
    const selectedPracticeType = providerRequest?.practiceType?.id;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isLogin()) {
            dispatch(updateRequest({
                ...providerRequest,
                showActivationKey: true
            }));
        } else {
            dispatch(updateRequest({
                ...providerRequest,
                showActivationKey: false
            })); 
        }
		getDomainList().then((success) => {
			if (success.status.error === false) {
				setPracticeType(success.domain);
			}
		}).catch((err) => {
			console.log("getDomainList: err", err);
		});
	}, []);
    useEffect(() => {
        if (practiceType?.length > 0 && isLogin()) {
            getProviderData().then((success) => {
                console.log(success?.status);
                if (success && success.status) {
                    const data = success;
                    const practiveTypeSelected = practiceType?.find((item) => item.id === data?.domainId);
                    dispatch(updateRequest({
                        ...providerRequest,
                        practiceType: practiveTypeSelected,
                        speciality: data?.speciality?.map((item) => item.id).toString(),
                        fullname: data?.firstName,
                        email: data?.email,
                        gender: data?.gender,
                        license: data?.licence,
                        phonenumber: data?.phone,
                        activationKey: data?.activationKey,
                        languages: data?.languages?.map(item => item.id).toString(),
                        services: data?.services?.map(item => item.id),
                        questionResponse: data?.questionResponse,
                        description: data?.description,
                        metaTitle: data?.seodata?.metaTitle ?? "",
                        metaDesc: data?.seodata?.metaDesc ?? "",
                        metaKeywords: data?.seodata?.metaKeywords !== "" ? data?.seodata?.metaKeywords?.split(",")?.map((item) => ({label: item, value: item})) : [],
                        educationDetailList: data?.educations,
                        certificationList: data?.certificates,
                        selectedClinics: data?.clinics?.map((item) => {
                            return {
                                ...item,
                                label: item?.name,
                                value: item.uniqueId,
                                selectedDays: item.hours?.filter((hour) => hour.active)?.map((item) => item.id),
                                hours: item?.hours?.map((item) => {
                                    return {
                                        ...item,
                                        startShow: moment(item.startHour, "LT"),
                                        endShow: moment(item.endHour, "LT"),
                                    }
                                }),
                            }
                        }),
                        openToWork: data?.clinics?.find((item) => item.openToWorkClinic === true) !== undefined,
                        previewFile: configs.IMAGE_URL + 'doctor/' + data?.photo?.name + '-original.' + data?.photo?.type,
                        editMode: true,
                        uploadedPhotos: data?.photos,
                        uniqueId: data?.uniqueId
                    }));
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [practiceType]);
    return (
        <section className="practice-step-sec">
            {
                providerRequest?.showActivationKey &&
                <ActivationModal></ActivationModal>
            }
            <div className="progress m-0 custom-progress overflow-visible">
                <div className="progress-bar overflow-visible" role="progressbar" style={{ "width": "14%" }}> <span
                    className="fw-medium text-primary progper">14%</span></div>
            </div>

            <div className="container-fluid border-bottom py-5 mb-5">
                <div className="row">
                    <div className="col-12">
                        <div className="top-menu-sec">
                            <ul className="p-0 m-0 d-md-flex justify-content-center">
                                <li className="step-list active">

                                    <div className="img-box">
                                        <i className="bi bi-plus-circle-dotted"></i>
                                    </div>
                                    <div className="txt-box">
                                        <small>Step 1/7</small>
                                        <span className="d-block fw-medium">Practice type</span>
                                    </div>

                                </li>
                                <li className="step-list">

                                    <div className="img-box">
                                        <i className="bi bi-person-add"></i>
                                    </div>
                                    <div className="align-self-center txt-box">
                                        <small>Step 2/7</small>
                                        <span className="d-block fw-medium">Personal Info</span>
                                    </div>

                                </li>
                                <li className="step-list">

                                    <div className="img-box">
                                        <i className="bi bi-asterisk"></i>
                                    </div>
                                    <div className="align-self-center txt-box">
                                        <small>Step 3/7</small>
                                        <span className="d-block fw-medium">Services</span>
                                    </div>

                                </li>

                                <li className="step-list">

                                    <div className="img-box">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <div className="align-self-center txt-box">
                                        <small>Step 4/7</small>
                                        <span className="d-block fw-medium">Discoverability Score</span>
                                    </div>

                                </li>
                                <li className="step-list">

                                    <div className="img-box">
                                        <i className="bi bi-award"></i>
                                    </div>
                                    <div className="align-self-center txt-box">
                                        <small>Step 5/7</small>
                                        <span className="d-block fw-medium">Education & Certifications</span>
                                    </div>

                                </li>
                                <li className="step-list">

                                    <div className="img-box">
                                        <i className="bi bi-images"></i>
                                    </div>
                                    <div className="align-self-center txt-box">
                                        <small>Step 6/7</small>
                                        <span className="d-block fw-medium">Upload images</span>
                                    </div>

                                </li>

                                <li className="step-list">

                                    <div className="img-box">
                                        <i className="bi bi-clock"></i>
                                    </div>
                                    <div className="align-self-center txt-box">
                                        <small>Step 7/7</small>
                                        <span className="d-block fw-medium">Availablity</span>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container steps-container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-10 col-12">

                        <div className="row">
                            <div className="col-12">
                                <div className="steps-title-section">
                                    <h2 className="mb-3">Select your Vertical</h2>
                                    <div className="fw-medium fs-5">Select your Vertical from list below</div>
                                    <div>Please ensure that all of the following information is correct. We will use this profile for google
                                        listing and marketing purposes.</div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {
                                practiceType.map((item) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <Icons item={item}></Icons>
                                        </React.Fragment>
                                    );
                                })
                            }

                        </div>
                        <div className="row">
                            <div className="col-12 my-4">
                                <div className="bottom-btn-sec text-end">
                                    <button disabled={isEmpty(selectedPracticeType)} className="btn btn-primary ms-1" onClick={() => {
                                        navigate('/step2');
                                    }}>Next</button>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}

const Icons = (props) => {
	const { item } = props;
	const [image, setImage] = useState('');
    const providerRequest = useSelector((state) => state.provider);
    const dispatch = useDispatch();
    const selectedPracticeType = providerRequest?.practiceType?.id;
    const setSelectedPracticeType = (item) => {
        dispatch(updateRequest({
            ...providerRequest,
            practiceType: item,
            speciality: ''
        }));
    }
	useEffect(() => {
		const loadImage = async () => {
			const _image = await import('../../images/' + item.icon)
			setImage(_image.default);
		}
		loadImage();
	}, []);
	return (
		<div className="col-6 col-md-3" key={item.id} id={"icon_"+ item.id}>
			<div className="select-practice-btn">
				<input type="radio" className="btn-check" name="select-practice-radio"
					checked={item.id === selectedPracticeType}
					id={item.id + item.title} value={item.id} onClick={(e) => { setSelectedPracticeType(item) }} />
				<label className="btn border rounded w-100" htmlFor={item.id + item.title}>
					<img src={image} className="m-3" />
                    <small className="practice-type-title">{item.title}</small>
				</label>
			</div>
		</div>
	)
}

const ActivationModal = () => {
    const providerRequest = useSelector((state) => state.provider);
    const showActivationKey = providerRequest?.showActivationKey;
    const [mobileNumber, setMobileNumber] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [showActivationKeyInput, setShowActivationKeyInput] = useState(false);
    const [activationKey, setActivationKey] = useState('');
    const [activationKeyError, setActivationKeyError] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [acceptTnc, setAcceptTnc] = useState(false);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(updateRequest({
            ...providerRequest,
            showActivationKey: false
        }));  
    }

    const handleSendKey = () => {
        return new Promise((resolve, reject) => {
            setEmailError("");
            if (email?.trim() === '' || !emailValidator(email?.trim())) {
                setEmailError("Please enter valid email");
                reject();
                return;
            }
            if (mobileNumber !== '' && mobileNumber.length === 10) {
                setMobileNumberError('');
                setIsLoading(true);
                sendActivationKey(mobileNumber, email).then((success) => {
                    setIsLoading(false);
                    if (success.status.error === false) {
                        setShowActivationKeyInput(true);
                        resolve();
                    } else {
                        setMobileNumberError('Send activation key failed');
                        reject();   
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    if (error.error === true) {
                        setMobileNumberError(error?.msg);
                    }
                    console.log(error);
                    reject();
                });
            } else {
                setMobileNumberError('Please enter valid mobile number');
                reject();
            }
        });
    }

    const _checkActivationKey = async () => {
        try {
            await handleSendKey();
            setEmailError("");
            if (email?.trim() === '' || !emailValidator(email?.trim())) {
                setEmailError("Please enter valid email");
                return;
            }
            if (activationKey !== '' && mobileNumber !== '') {
                setIsLoading(true);
                await checkActivationKey({
                    "key": activationKey,
                    "phone": mobileNumber,
                    "email": email
                });
                setIsLoading(false);
                setActivationKeyError('');
                dispatch(updateRequest({
                    ...providerRequest,
                    phonenumber: mobileNumber,
                    activationKey: activationKey,
                    showActivationKey: false,
                    email: email
                }));
            } else {
                setActivationKeyError('Please enter valid activation key');
            }
        } catch (error) {
            if (error?.error === true)  {
                setActivationKeyError(error?.msg ?? 'Please enter valid activation key');
            }
        }
    }
    return (
        <Modal show={showActivationKey}>
            <Modal.Header>
                <Modal.Title>Provider Sign Up</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <label for="Specialties" class="form-label">Email</label>
                    <input type="text" class="form-control" id="email" name="email" value={email} onChange={(e) => {
                        setEmail(e.target.value);
                        setShowActivationKeyInput(false);
                    }} placeholder="Enter your email"/>
                    {
                        emailError !== '' &&
                        <div class="text-danger">{emailError}</div>
                    }
                </div>
                <div className="mb-3">
                    <label for="Specialties" class="form-label">Mobile Number</label>
                    <input type="text" class="form-control" id="phonenumber" name="phonenumber" value={mobileNumber} onChange={(e) => {
                        setMobileNumber(e.target.value);
                        setShowActivationKeyInput(false);
                    }} placeholder="Enter your mobile number to receive the account activation key"/>
                    {
                        mobileNumberError !== '' &&
                        <div class="text-danger">{mobileNumberError}</div>
                    }
                </div>
                {
                   showActivationKeyInput &&
                   <div className="mb-3">
                        <label for="Specialties" class="form-label">Activation key</label>
                        <input type="text" class="form-control" id="activationKey" name="activationKey" value={activationKey} onChange={(e) => {
                            setActivationKey(e.target.value);
                        }} placeholder="" />
                        {
                            activationKeyError !== '' &&
                            <div class="text-danger">{activationKeyError}</div>
                        }
                    </div>
                }
                <div className="mb-3">
                    <input className="form-check-input" type="checkbox" name="termsandcond" id="termsandcond" value="Terms and conditions" checked={acceptTnc} onChange={(e) => {
                        setAcceptTnc(!acceptTnc);
                    }}/>
                    <label className="form-check-label mx-2" htmlFor="termsandcond">Agree to <a href="https://www.xcare.app/app-user-terms-of-service/" target="_blank">terms and conditions</a></label>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <span>
                        Already user? <NavLink to="/signin" className="text-decoration-none">Sign In</NavLink>
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button disabled={loading || !acceptTnc} variant="primary" onClick={_checkActivationKey}>
                {
                    loading ? <>Loading...</> : <>Submit</>
                }
            </Button>
            </Modal.Footer>
        </Modal>
    )
}