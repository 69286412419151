import { getCookie } from "./login";

/**
 * 
 * @param isMultiPart true if request is multipart/form-data : default to false
 */
export const attachHeader = (isMultiPart = false, isAuthHeader = false) => {
    try {     
        let headers = null;
        const login = getCookie();
        if (isAuthHeader) {
            if (isMultiPart) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'provider'
                }
            } else {
                headers = {
                    'Content-Type': 'application/json',
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'provider'
                }
            }
        } else {
            if (isMultiPart) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                    'xprApp': 'provider'
                }
            } else {
                headers = {
                    'Content-Type': 'application/json',
                    'xprApp': 'provider'
                }
            }
        }
        return headers;
    } catch (error) {
        window.location.href = '/';
    }
};

export const prepareCookie = () => {
    const json = getCookie();
    let cookieString = '';
    for (let key in json) {
        if (json.hasOwnProperty(key)) {
            let val = json[key];
            cookieString = cookieString + key + '=' + val + ';';
        }
    }
    return cookieString;
}