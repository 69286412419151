/**
 * Global app configurations.
 */
const STAGING_HOST = ['provider.staging.xcarepro.com', 'provider.stagingus.xcarepro.com'];
const DEV_HOST = ['provider.dev.xcarepro.com', 'localhost', '127.0.0.1'];
const PROD_HOST = ['provider.xcarepro.com', 'provider.us.xcarepro.com'];
const hostname = window.location.hostname;
let configs = {};
if (DEV_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    let SOURCE = "https://dev.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'provider/',
        API_URL_V2: SOURCE + 'api_v2/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
    }
} else if (STAGING_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    let SOURCE = "https://staging.xcarepro.com/";
    if (window.location.hostname === "app.stagingus.xcarepro.com") {
        SOURCE = "https://stagingus.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'provider/',
        API_URL_V2: SOURCE + 'api_v2/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
    }
} else if (PROD_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Production environment
    let SOURCE = "https://www.xcarepro.com/";
    if (window.location.hostname === "app.us.xcarepro.com") {
        SOURCE = "https://us.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'provider/',
        API_URL_V2: SOURCE + 'api_v2/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
    }
}
export default configs;