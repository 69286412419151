export const METHOD_TYPE = {
    GET: 1,
    POST: 2,
    PUT: 3,
    DELETE: 4,
}
export const BODY_TYPE = {
    RAW: 1,
    FORM_DATA: 2
}
