import React from 'react';
export function showAddress(address) {
    return (
        <>
            {address?.route !== "" ? address?.route + ', ' : ''}
            {
                (address?.sublocality && address?.sublocality !== "") ?
                    <>{address?.sublocality},</>
                    : (address?.neighborhood && address?.neighborhood !== "") ?
                        <>{address?.neighborhood},</>
                        : (address?.locality && address?.locality !== "") ?
                            <>{address?.locality + ','}</> : null
            }

            {
                address?.address_level_1_short !== "" ? address?.address_level_1_short + ', ' : ''
            }
            {
                address?.postal !== "" ? address?.postal : ''
            }
        </>
    )
}