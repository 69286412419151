import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateRequest } from "../../store/slices/provider";
export const Step5 = () => {
    const providerRequest = useSelector((state) => state.provider);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [associationName, setAssociationName] = useState('');
    const [receviedDate, setreceviedDate] = useState(new Date());
    const [registrationNumber, setregistrationNumber] = useState('');

    const [degree, setDegree] = useState('');
    const [college, setCollege] = useState('');
    const [year, setYear] = useState(moment().toDate());


    const [associationNameValidation, setAssociationNameValidation] = useState({ associationNameValidation: false, msg: '' });
    const [registrationNumberValidation, setregistrationNumberValidation] = useState({ registrationNumberValidation: false, msg: '' });
    const [degreeValidation, setdegreeValidation] = useState({ degreeValidation: false, msg: '' });
    const [collegeValidation, setcollegeValidation] = useState({ collegeValidation: false, msg: '' });

    const addEducationDetail = () => {

        if (associationName === '') {
            setAssociationNameValidation({ associationNameValidation: true, msg: "Association Name Requried" });
            return;
        }
        if (registrationNumber === '') {
            setregistrationNumberValidation({ registrationNumberValidation: true, msg: "Register Number is Requried" });
            return;
        }
        const edurequest = {
            associationName: associationName,
            receviedDate: moment(receviedDate).format('DD-MM-YYYY'),
            registrationNumber: registrationNumber
        }
        dispatch(updateRequest({
            ...providerRequest,
            educationDetailList: [...providerRequest?.educationDetailList, edurequest]
        }));
        setAssociationName(''); setreceviedDate(new Date()); setregistrationNumber('');
        setAssociationNameValidation({ associationNameValidation: false, msg: "" });
        setregistrationNumberValidation({ registrationNumberValidation: false, msg: "" });
    };


    const addCertificationDetail = () => {

        if (degree === '') {
            setdegreeValidation({ degreeValidation: true, msg: "Degree is Requried" });
            return;
        }
        if (college === '') {
            setcollegeValidation({ collegeValidation: true, msg: "College is Requried" });
            return;
        }
        const certificationrequest = {
            degree: degree,
            college: college,
            year: moment(year ?? moment().toDate()).format("YYYY")
        }
        dispatch(updateRequest({
            ...providerRequest,
            certificationList: [...providerRequest?.certificationList, certificationrequest]
        }));
        setDegree(''); setYear(moment().toDate()); setCollege('');
        setdegreeValidation({ degreeValidation: false, msg: "" });
        setcollegeValidation({ collegeValidation: false, msg: "" });
    };

    const removeEducationDetail = (index) => {
        dispatch(updateRequest({
            ...providerRequest,
            educationDetailList: providerRequest?.educationDetailList?.filter((item, iIndex) => {
                return iIndex !== index
            })
        }));
    };

    const removeCertificationDetail = (index) => {
        dispatch(updateRequest({
            ...providerRequest,
            certificationList: providerRequest?.certificationList.filter((item, iIndex) => {
                return iIndex !== index
            })
        }));
    };

    return (
        <section class="practice-step-sec">

            <div class="progress custom-progress">
                <div class="progress-bar" role="progressbar" style={{ "width": "70%" }}> <span
                    class="fw-medium text-primary progper">70%</span></div>
            </div>

            <div class="step-navigation">
                <div class="container-fluid">
                    <div class="top-menu-sec">
                        <ul class="p-0 m-0 d-md-flex justify-content-center">
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-plus-circle-dotted"></i>
                                </div>
                                <div class="txt-box">
                                    <small>Step 1/8</small>
                                    <span class="d-block fw-medium">Practice type</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-person-add"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 2/8</small>
                                    <span class="d-block fw-medium">Personal Info</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-asterisk"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 3/8</small>
                                    <span class="d-block fw-medium">Services</span>
                                </div>

                            </li>
                            <li class="step-list completed">

                                <div class="img-box">
                                    <i class="bi bi-search"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 4/8</small>
                                    <span class="d-block fw-medium">Discoverability Score</span>
                                </div>

                            </li>
                            <li class="step-list active">

                                <div class="img-box">
                                    <i class="bi bi-award"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 5/8</small>
                                    <span class="d-block fw-medium">Education & Certifications</span>
                                </div>

                            </li>
                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-images"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 6/8</small>
                                    <span class="d-block fw-medium">Upload images</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 7/8</small>
                                    <span class="d-block fw-medium">Availablity</span>
                                </div>

                            </li>

                            <li class="step-list">

                                <div class="img-box">
                                    <i class="bi bi-clock-history"></i>
                                </div>
                                <div class="align-self-center txt-box">
                                    <small>Step 8/8</small>
                                    <span class="d-block fw-medium">Open to work</span>
                                </div>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="step-content-area">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-12">

                            <div class="step-content-area-flex">

                                <div class="steps-title-section">
                                    <h2 class="steps-title">Education & Certifications</h2>
                                    <div class="steps-subtitle">Add {providerRequest?.fullname} board Certifications.</div>
                                    <div class="steps-desc">Add {providerRequest?.fullname} Board Certifications Details.</div>
                                </div>

                                <div class="step-content">
                                    <div class="row">
                                        <div class="col-12">
                                            {
                                                providerRequest?.educationDetailList?.map((item, index) => {
                                                    return (
                                                        <div className="d-flex border rounded justify-content-between edu-box p-4 my-3">
                                                            <div className="association-name me-3">
                                                                <small className="d-block text-muted">Association Name</small>
                                                                {item.associationName}
                                                            </div>
                                                            <div className="received-date me-3">
                                                                <small className="d-block text-muted">Received date</small>
                                                                {item.receviedDate}
                                                            </div>
                                                            <div className="registration-no me-3">
                                                                <small className="d-block text-muted">Registration Number</small>
                                                                {item.registrationNumber}
                                                            </div>
                                                            <button class="btn btn-outline-danger" onClick={() => { removeEducationDetail(index) }}><i class="bi bi-trash"></i></button>
                                                        </div>
                                                    )
                                                })
                                            }


                                            <div class="d-flex justify-content-between align-items-start add-new-edu">
                                                <div class="col-3">
                                                    <label htmlFor="medAssociation" className="form-label d-block">Association Name</label>
                                                    <input type="text" className="form-control" id="medAssociation" placeholder="ex. Medical Association" value={associationName} onChange={(e) => { 
                                                        setAssociationName(e.target.value);
                                                        setAssociationNameValidation({ associationNameValidation: false, msg: "" });
                                                    }} />
                                                    {
                                                        associationNameValidation.associationNameValidation === true &&
                                                        <div className="text-danger">{associationNameValidation.msg}</div>
                                                    }
                                                </div>

                                                <div class="col-3 miuif_margin">
                                                    <label for="receivedDate" class="form-label d-block">Received date</label>
                                                    <input type="date" class="form-control" id="" placeholder="" value={receviedDate} onChange={e => {
                                                        setreceviedDate(e.target.value);
                                                    }} />

                                                </div>

                                                <div class="col-3">
                                                    <label htmlFor="regNumber" className="form-label d-block">Registration Number</label>
                                                    <input type="text" className="form-control" id="regNumber" placeholder="ex. DA-xxxx or xxxx etc..." value={registrationNumber} onChange={(e) => { 
                                                        setregistrationNumber(e.target.value) 
                                                        setregistrationNumberValidation({ registrationNumberValidation: false, msg: '' });
                                                    }} />
                                                    {
                                                        registrationNumberValidation.registrationNumberValidation === true &&
                                                        <div className="text-danger">{registrationNumberValidation.msg}</div>
                                                    }
                                                </div>

                                                <button class="btn btn-primary" onClick={() => { addEducationDetail() }}>Add</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">

                                        <div class="col-12 mt-5">
                                            <div class="steps-title-section mb-4">
                                                <div class="steps-subtitle">Add {providerRequest?.fullname} education details.</div>
                                                <div class="steps-desc">Add {providerRequest?.fullname} Education Details.</div>
                                            </div>


                                        </div>

                                        <div class="col-12">
                                            {
                                                providerRequest?.certificationList?.map((item, index) => {
                                                    return (
                                                        <div className="d-flex border rounded justify-content-between edu-box p-4 my-3">
                                                            <div className="association-name me-3">
                                                                <small className="d-block text-muted">Degree</small>
                                                                {item.degree}
                                                            </div>
                                                            <div className="received-date me-3">
                                                                <small className="d-block text-muted">University/College</small>
                                                                {item.college}
                                                            </div>
                                                            <div className="registration-no me-3">
                                                                <small className="d-block text-muted">Year</small>
                                                                {item.year}
                                                            </div>
                                                            <button className="btn p-0" onClick={() => { removeCertificationDetail(index) }}><i class="bi bi-trash"></i></button>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div class="d-flex justify-content-between align-items-start add-new-edu mt-4">
                                                <div class="col-3">
                                                    <label htmlFor="degree" className="form-label d-block">Degree</label>
                                                    <input type="text" className="form-control" id="degree" placeholder="ex. Doctor of Dental Surgery" value={degree} onChange={(e) => { 
                                                        setDegree(e.target.value) 
                                                        setdegreeValidation({
                                                            degreeValidation: false, msg: ""
                                                        });
                                                    }} />
                                                    {
                                                        degreeValidation.degreeValidation === true &&
                                                        <div className="text-danger">{degreeValidation.msg}</div>
                                                    }
                                                </div>
                                                <div class="col-3">
                                                    <label htmlFor="college" className="form-label d-block">University/College</label>
                                                    <input type="text" className="form-control" id="college" placeholder="Canadian dentist " value={college} onChange={(e) => { 
                                                        setCollege(e.target.value) 
                                                        setcollegeValidation({
                                                            collegeValidation: false, msg: ""
                                                        });
                                                    }} />
                                                    {
                                                        collegeValidation.collegeValidation === true &&
                                                        <div className="text-danger">{collegeValidation.msg}</div>
                                                    }
                                                </div>
                                                <div class="col-3 miuif_margin">
                                                    <label for="year" class="form-label d-block">Year</label>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={year ?? moment().toDate()}
                                                        onChange={(year) => { setYear(year) }}
                                                        showYearPicker
                                                        dateFormat="yyyy"
                                                    />
                                                </div>
                                                <button class="btn btn-primary"  onClick={() => { addCertificationDetail() }}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="step-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="bottom-btn-sec d-flex justify-content-between">
                                <button class="btn btn-outline-primary me-1" onClick={() => {
                                    navigate('/step4')
                                }}>Back</button>
                                <button class="btn btn-primary ms-1" onClick={() => {
                                    navigate('/step6')
                                }}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}